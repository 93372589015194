var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":_vm.xs ?
    {
      'items-per-page-options': [5, 10, 20, 100],
      itemsPerPageText: ''
    } :
    {
      'items-per-page-options': [5, 10, 20, 100]
    },"sort-by":"deviceCount","items-per-page":5,"sort-desc":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_vm._v(" 장치 현황 "),_c('v-spacer'),_c('v-btn',{attrs:{"to":"/model","icon":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-right")])],1)],1),_c('v-divider')]},proxy:true},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('display-model',{attrs:{"model":item.id || '전체'}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }