
























import { Component, Vue } from 'vue-property-decorator'
import ModelStore from '@/store/model'

@Component
export default class HomeTotalItem extends Vue {
  get items () { return ModelStore.items }
  get total () {
    const total = { deviceCount: 0, firmwareCount: 0, updateCount: 0 }
    this.items.forEach(item => {
      if (typeof item.deviceCount === 'number') total.deviceCount += item.deviceCount
      if (typeof item.firmwareCount === 'number') total.firmwareCount += item.firmwareCount
      if (typeof item.updateCount === 'number') total.updateCount += item.updateCount
    })
    return total
  }
}
