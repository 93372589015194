



import { Vue, Component } from 'vue-property-decorator'
import HomeIndex from '@/components/home/index.vue'

@Component({ components: { HomeIndex } })
export default class ViewHome extends Vue {

}

