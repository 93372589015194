
























import { Component, Vue, Prop } from 'vue-property-decorator'
import { Device } from '@/models/devices'
import DeviceItem from '@/components/device/item.vue'

@Component<DeviceItemChip>({
  components: { DeviceItem }
})
export default class DeviceItemChip extends Vue {
  @Prop({ type: Object, required: true }) item!: Device
  icons = [
    'mdi-pause',
    'mdi-update',
    'mdi-autorenew mdi-spin',
    'mdi-check'
  ]

  get updateStat () {
    return this.icons[this.item.update]
  }
}
