





































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Model } from '@/models/models'
import DisplayModel from '@/components/common/display-model.vue'

@Component<HomeModelList>({
  components: { DisplayModel }
})
export default class HomeModelList extends Vue {
  @Prop({ type: Array, required: true }) items!: Model[]

  headers = [
    { value: 'id', text: '모델명' },
    { value: 'deviceCount', text: '장치개수' },
    { value: 'firmwareCount', text: '펌웨어개수' },
    { value: 'updateCount', text: '업데이트중' },
    { value: 'viewCount', text: '감시중' }
  ]

  get xs () { return this.$vuetify.breakpoint.xs }
}
