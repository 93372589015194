
import { Doughnut, mixins } from 'vue-chartjs'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
const { reactiveProp } = mixins

  @Component({
    extends: Doughnut,
    mixins: [reactiveProp]
  })
export default class BarChart extends Vue {
    @Prop()
    chartData: any

    @Prop({ default: function () { return {} } })
    options!: object

    public renderChart!: (chartData: any, options: any) => void

    mounted () {
      this.renderChart(this.chartData, this.options)
    }
}
