
































import { Component, Vue } from 'vue-property-decorator'
import deviceCollection, { Device } from '@/models/devices'
import DeviceItemChip from '@/components/device/item-chip.vue'

@Component<HomeDeviceList>({
  components: { DeviceItemChip },
  created () {
    this.subscribe()
  },
  destroyed () {
    if (this.unsubscribe) this.unsubscribe()
  }
})
export default class HomeDeviceList extends Vue {
  unsubscribe: Function | null = null
  // snapshots: firebase.firestore.QuerySnapshot<Device>
  items: Device[] = []

  icons = [
    { value: 'mdi-pause', text: '정지' },
    { value: 'mdi-update', text: '업데이트 대기' },
    { value: 'mdi-autorenew mdi-spin', text: '업데이트 중' },
    { value: 'mdi-check', text: '업데이트 완료' }
  ]

  subscribe () {
    deviceCollection
      .where('view', '==', true)
      // .orderBy('update', 'asc')
      // .orderBy('view', 'asc')
      // .orderBy('info.modelNo', 'desc')
      .limit(10).onSnapshot(sn => {
        this.items = sn.docs.map(doc => doc.data())
      })
  }
}
