




















import { Component, Vue, Prop } from 'vue-property-decorator'
import DoughnutChart from '@/components/home/doughnut-chart.vue'

@Component<HomeChart>({ components: { DoughnutChart } })
export default class HomeChart extends Vue {
  @Prop({ type: String, required: true }) title!: string
  @Prop({ type: Number, required: true }) total!: number
  @Prop({ type: Array, required: true }) labels!: string[]
  @Prop({ type: Array, required: true }) data!: number[]

  options = {
    legend: {
      display: this.$vuetify.breakpoint.lgAndUp,
      position: 'bottom'
    },
    responsive: true,
    aspectRatio: 4,
    maintainAspectRatio: false
  }

  loading = false

  get chartData () {
    return {
      labels: this.labels,
      datasets: [
        {
          label: 'devices',
          backgroundColor: this.colors,
          data: this.data
        }
      ]
    }
  }

  get colors () {
    const colors = []
    for (let i = 0; i < this.data.length; i++) {
      colors.push(this.dynamicColors())
    }
    return colors
  }

  dynamicColors () {
    const r = Math.floor(Math.random() * 255)
    const g = Math.floor(Math.random() * 255)
    const b = Math.floor(Math.random() * 255)
    return 'rgb(' + r + ',' + g + ',' + b + ')'
  }

  toggleLegend () {
    this.options.legend.display = !this.options.legend.display
    this.loading = true
    setTimeout(() => {
      this.loading = false
    }, 10)
  }
}
