






















import { Component, Vue } from 'vue-property-decorator'
import HomeTotalItem from '@/components/home/total-item.vue'
import HomeChart from '@/components/home/chart.vue'
import HomeModelList from '@/components/home/model-list.vue'
import HomeDeviceList from '@/components/home/device-list.vue'
import ModelStore from '@/store/model'

@Component({ components: { HomeChart, HomeTotalItem, HomeModelList, HomeDeviceList } })
export default class HomeIndex extends Vue {
  get items () { return ModelStore.items }

  get total () {
    return ModelStore.total
  }

  get fullItems () {
    return ModelStore.fullItems
  }

  get modelIds () {
    return this.items.map(item => `${item.id}`)
  }

  get deviceCounts () {
    return this.items.map(item => item.deviceCount)
  }

  get updateCounts () {
    return this.items.map(item => item.updateCount)
  }

  get firmwareCounts () {
    return this.items.map(item => item.firmwareCount)
  }
}
